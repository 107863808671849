<template>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item :to="item.href" class="text-subtitle-2 crumb-item" :disabled="item.disabled" exact>
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
          <!-- ini multi select dan tanggal -->
            <v-col cols="12">
                <v-card>
                    <v-progress-linear
                        :active="loading" :indeterminate="loading" absolute bottom color="deep-purple accent-4"
                    ></v-progress-linear>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="2" xs="12" sm="5" md="2">
                                <v-autocomplete solo clearable v-model="office" :items="offices" item-value="office_id" item-text="office" label="MILL ID"></v-autocomplete>
                            </v-col>
                            <v-col cols="2" xs="12" sm="5" md="2">
                                <v-autocomplete solo clearable v-model="approve" :items="approves" item-value="value" item-text="descr" label="Approved"></v-autocomplete>
                            </v-col>
                            <v-col cols="2" xs="12" sm="5" md="2">
                                <v-autocomplete solo clearable v-model="status" :items="statuses" item-value="value" item-text="status" label="Status"></v-autocomplete>
                            </v-col>
                            <!-- Tanggal -->
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu ref="modal" v-model="modal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attr }">
                                    <v-text-field solo v-model="date_from" label="Date from" prepend-icon="mdi-calendar" readonly class="ma-0 pa-0" hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_from" no-title @input="modal = false"></v-date-picker>
                                 </v-menu>
                            </v-col>
                            <!-- Batas tanggal -->

                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu ref="modal_to" v-model="modal_to" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">   
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field solo v-model="date_to" label="Date to" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="ma-0 pa-0" hide-details=true></v-text-field>
                                    </template>
                                  <v-date-picker v-model="date_to" no-title @input="modal_to = false"></v-date-picker>
                                     </v-menu>
                            </v-col>
                              <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-1" 
                                color="info" elevation="2" large @click="getPullData()">Search</v-btn>
                            </v-col>
                             <!-- <v-col cols="2" xs="12" sm="5" md="2">
                                <v-btn class="mr-2" color="error" elevation="2" large @click="getPullData()">Search</v-btn>
                            </v-col> -->
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <!-- Ini Batas Nya -->
            <v-col cols="12" style="padding-bottom: 100px">
                 <!-- <v-card v-show="display == 'none' ? false: true"> -->
                <v-card>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Result
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="$store.state.text" append-icon="mdi-magnify" label="Search Deliv ID" single-line @keyup.enter="search" hide-details></v-text-field>
                                </v-card-title>
                                <v-data-table :headers="headers" :items="purchases" :options.sync="options" :loading="loading" loading-text="Please wait, retrieving data" :server-items-length="totalItems" class="elevation-1">
                                    <template v-slot:[`item.approve1`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <div v-if="user_approve1 == 'approve1'">
                                                    <v-chip class="ma-2" color="primary" v-if="item.dt_aprv === '1900-01-01 00:00:00.000' && item.dt_aprv2 == '1900-01-01 00:00:00.000'" @click="setApprove1(item)" v-on="on">
                                                        <v-icon center>mdi-check-circle</v-icon>
                                                        Confirmation
                                                    </v-chip>
                                                    <v-chip class="ma-2" color="red" text-color="white" v-else-if="item.dt_aprv != '1900-01-01 00:00:00.000' && item.dt_aprv2 == '1900-01-01 00:00:00.000'" @click="setUnApprove(item)" v-on="on">
                                                        <v-icon center> 
                                                        mdi-replay
                                                        </v-icon>
                                                         Confirmation
                                                        </v-chip>
                                                    <v-chip class="ma-2" color="success" text-color="white" v-else-if="item.dt_aprv != '1900-01-01 00:00:00.000' && item.dt_aprv2 != '1900-01-01 00:00:00.000'">
                                                        {{item.dt_aprv}}
                                                    </v-chip>
                                                </div>

                                                <div v-else>
                                                    <v-chip class="ma-2" color="red" text-color="white" v-if="item.dt_aprv === '1900-01-01 00:00:00.000'">
                                                        Not Approved
                                                    </v-chip>

                                                    <v-chip class="ma-2" color="success" text-color="white" v-else-if="item.dt_aprv != '1900-01-01 00:00:00.000'">
                                                        {{item.dt_aprv}}
                                                    </v-chip>
                                                </div>
                                            </template>
                                            <span>Confirm</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:[`item.approve2`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <div v-if="user_approve2 == 'approve2'">
                                                    <v-chip class="ma-2" color="primary" v-if="item.dt_aprv != '1900-01-01 00:00:00.000' && item.dt_aprv2 === '1900-01-01 00:00:00.000'" @click="setApprove2(item)" v-on="on">
                                                        <v-icon center> 
                                                        mdi-check-circle
                                                        </v-icon>
                                                        Confirmation
                                                    </v-chip>
                                                    <v-chip class="ma-2" color="red" text-color="white" v-if="item.dt_aprv != '1900-01-01 00:00:00.000' && item.dt_aprv2 != '1900-01-01 00:00:00.000'" @click="setUnApprove2(item)" v-on="on">
                                                        <v-icon center> 
                                                        mdi-replay
                                                        </v-icon>
                                                         Confirmation
                                                    </v-chip>
                                                    <v-chip class="ma-2" color="red" text-color="white" v-else-if="item.dt_aprv == '1900-01-01 00:00:00.000' && item.dt_aprv2 == '1900-01-01 00:00:00.000'">
                                                        <v-icon center> 
                                                         mdi-window-close
                                                        </v-icon>
                                                        Not Approved
                                                        </v-chip>
                                                     </div>

                                                <div v-else>
                                                    <v-chip class="ma-2" color="red" text-color="white" v-if="item.dt_aprv2 === '1900-01-01 00:00:00.000'">
                                                        Not Approved
                                                    </v-chip>
                                                    <v-chip class="ma-2" color="success" text-color="white" v-else-if="item.dt_aprv2 != '1900-01-01 00:00:00.000'">
                                                        {{item.dt_aprv2}}
                                                    </v-chip>
                                                </div>   
                                            </template>
                                            <span>Confirm</span>
                                        </v-tooltip>
                                    </template>

                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Show</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
              
                </v-card>
                <v-dialog v-model="dialog" max-width="1000px">                
                    <v-card>
                        <v-card-title>Detail Deliv Item</v-card-title>
                        <v-card-text>
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-card-title>Deliv Item
                                            <v-spacer></v-spacer>
                                            <v-text-field v-model="search_detail" append-icon="mdi-magnify" label="Search Deliv ID" single-line @keyup.enter="search_temp" hide-details></v-text-field>
                                        </v-card-title>
                                        <v-data-table :headers="header_po_items" :items="po_itemsDetail" :options.sync="option_po_items" :loading="loading3" loading-text="Please wait, retrieving data" :server-items-length="totalItem_po_items" page-count="10" class="elevation-1">
                                            <template v-slot:[`item.qty`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.qty) }}
                                            </template>
                                            <template v-slot:[`item.wgt`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt) }}
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogAgree" persistent max-width="500">
                    <v-card>
                        <v-card-title class="text-h5">
                        Are you sure you approve this DO?
                        </v-card-title>
                        <v-card-text>If you are sure click Agree, if not click Cancel.</v-card-text>
                        <v-card-actions>
                        <v-btn color="green darken-1" text @click="dialogAgree = false">
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn class="ma-2" :loading="loading4" :disabled="loading4" color="green darken-1" text-color="white" @click="submitApprove1()">
                            <v-icon>mdi-check-circle-outline</v-icon>
                                Agree
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                <v-dialog v-model="dialogAgree2" persistent max-width="500">
                    <v-card>
                        <v-card-title class="text-h5">
                        Are you sure you approve this DO?
                        </v-card-title>
                        <v-card-text>If you are sure click Agree, if not click Cancel.</v-card-text>
                        <v-card-actions>
                        <v-btn color="green darken-1" text @click="dialogAgree2 = false">
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn class="ma-2" :loading="loading5" :disabled="loading5" color="green darken-1" text-color="white" @click="submitApprove2()">
                            <v-icon>mdi-check-circle-outline</v-icon>
                             Agree
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                <v-dialog v-model="dialogDisagree" persistent max-width="500">
                    <v-card>
                        <v-card-title class="text-h5">
                        Are you sure you Rollback Approve 1 this DO?
                        </v-card-title>
                        <v-card-text>If you are sure click Agree, if not click Cancel.</v-card-text>
                        <v-card-actions>
                        <v-btn color="green darken-1" text @click="dialogDisagree = false">
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn class="ma-2" :loading="loading5" :disabled="loading5" color="green darken-1" text-color="white" @click="submitUnApprove()">
                            <v-icon>mdi-check-circle-outline</v-icon>
                             Agree
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                <v-dialog
                v-model="dialogDisagree2"
                persistent
                max-width="500"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                        Are you sure you Rollback Approve 2 this DO?
                        </v-card-title>
                        <v-card-text>If you are sure click Agree, if not click Cancel.</v-card-text>
                        <v-card-actions>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialogDisagree2 = false"
                        >
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ma-2"
                            :loading="loading5"
                            :disabled="loading5"
                            color="green darken-1"
                            text-color="white"
                            @click="submitUnApprove2()"
                            >
                            <v-icon>mdi-check-circle-outline</v-icon>
                             Agree
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
      </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'ARUNA',
                disabled: false,
                href: '/admin/aruna',
                },
                {
                text: 'Approval',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'DO Approval',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            offices: [{
                office_id : 'ARUNA',
                office : 'Sunrise Mill'
            }],
            office: 'ARUNA',
            departments: [],
            department: '',
            approves: [
                {
                    descr: 'All',
                    value: 'all'
                },
                {
                    descr: 'Not Approved 1',
                    value: 'approve1'
                },
                {
                    descr: 'Not Approved 2',
                    value: 'approve2'
                }
            ],
            approve: '',
            status: '',
            statuses: [
                {
                    status: 'OPEN',
                    value: 'O'
                },
                {
                    status: 'CLOSE',
                    value: 'C'
                }
            ],
            raws: [
                {
                    raw: 'Yes',
                    value: 'Y'
                },
                {
                    raw: 'No',
                    value: 'N'
                }
            ],
            raw: '',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            display: 'none',
            purchases: [],
            headers: [
                {
                    text: 'Deliv ID',
                    align: 'start',
                    sortable: true,
                    value: 'deliv_id',
                },
                { text: 'Date Trans', value: 'dt_trx' },
                { text: 'Expedisi', value: 'expd_name' },
                { text: 'Customer', value: 'cust_name' },
                { text: 'Status', value: 'stat' },
                { text: 'Approve 1',  value: 'approve1', align: 'center', sortable: false },
                { text: 'Approve 2',  value: 'approve2', align: 'center', sortable: false },
                { text: 'Actions',  value: 'actions', sortable: false },
            ],
            totalItems: 10,
            options: {},
            loading2: false,
            search_detail: '',
            header_po_items:[
                {
                    text: 'Deliv ID',
                    align: 'start',
                    sortable: true,
                    value: 'deliv_id',
                },
                { text: 'Order ID', value: 'order_id' },
                { text: 'Prod name', value: 'prod_name' },
                { text: 'Warehouse', value: 'warehouse' },
                { text: 'Weight', value: 'wgt' },
                { text: 'Length', value: 'length' },
                { text: 'Weight Order', value: 'wgt_ord' }
            ],
            po_items: [],
            po_itemsDetail: [],
            po_item: '',
            option_po_items: {},
            loading3: false,
            loading4: false,
            loading5: false,
            totalItem_po_items: 10,
            dialog: false,
            data_var: {
                entity_id : 'ARUNA',
                appl_id : 'APPROVAL'
            },
            user_approve1: '',
            user_approve2: '',
            dialogAgree: false,
            dialogAgree2: false,
            dialogDisagree2: false,
            dialogDisagree: false,
            pagination: 1,
            pagination_item: 1,
            loading_reject: false,
            raw_flag: '',
            disabled_search: false
        }
    },
    mounted(){
        this.getEnvConf()
        //this.search()
    },
    methods:{
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'APRVDO1') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.user_approve1 = res.data.data[i]['var_value']
                            this.approve = 'approve1'

                        }
                    }
                    
                    if (res.data.data[i]['var_id'] === 'APRVDO2') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.user_approve2 = res.data.data[i]['var_value']
                            this.approve = 'approve2'

                        }
                    }

                }

            })

            

        },
        search(){
           // this.pagination = 1
           // this.getPullData(1, 10, true)
        },
        search_temp(){
            this.pagination_item = 1
            this.getPullDataDetail(1, 10, this.po_item)
        },
        close() {
            this.dialog = false
        },
      showItem(item){
            console.log(item);
            this.po_item = item
            // this.po_items = []
            this.dialog = true
            this.pagination_item = 1
            this.getPullDataDetail(this.po_item)
            
        },
        async getPullDataDetail(item){
            this.po_itemsDetail =[]
            this.loading3 = true
            //console.log(item)
            await axios.get(`${process.env.VUE_APP_URL}/api/aruna/do/show?deliv_id=${item.deliv_id}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
              // console.log(res.data.result)
                this.loading3 = false
                this.po_itemsDetail = res.data.result
                //this.totalItem_po_items = res.data.total
                
            })
        },
        async getPullData(){
            this.loading = true
            console.log(this.user_approve1)
            if (this.date_from) {
                this.dateStartFormatted = new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.date_to) {
                this.dateEndFormatted = new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }
            await axios.get(`${process.env.VUE_APP_URL}/api/aruna/do?search=${this.$store.state.text}&txtApproved=${this.approve ? this.approve : ""}&txtStatus=${this.status ? this.status : ""}&start_date=${this.dateStartFormatted ? this.dateStartFormatted : ""}&end_date=${this.dateEndFormatted ? this.dateEndFormatted : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
              //  console.log(res.data.total)
                this.display = 'block'
                this.loading = false
                //this.purchases = res.data.data
                this.purchases = res.data.result
               
            })
        },
        setApprove1(item){
            this.dialogAgree = true
            this.po_item = item
        },
        setApprove2(item){
            this.dialogAgree2 = true
            this.po_item = item
        },
        setUnApprove(item){
            this.dialogDisagree = true
            this.po_item = item
        },
        setUnApprove2(item){
            this.dialogDisagree2 = true
            this.po_item = item
        },
        async submitApprove1(){
            this.loading4 = true

            await axios.post(`${process.env.VUE_APP_URL}/api/aruna/do/approve`, {
                'deliv_id': this.po_item.deliv_id,
                'approve': 1
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading4 = false
                this.dialogAgree = false
                this.getPullData()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Approve 1",
                    visible: true
                };
            }).catch(err => {
                this.loading4 = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        async submitApprove2(){
            this.loading5 = true

            await axios.post(`${process.env.VUE_APP_URL}/api/aruna/do/approve`, {
                'deliv_id': this.po_item.deliv_id,
                'approve': 2
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading5 = false
                this.dialogAgree2 = false
                this.getPullData()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Approve 2",
                    visible: true
                };
            }).catch(err => {
                this.loading5 = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        async submitUnApprove(){
            this.loading5 = true

            await axios.post(`${process.env.VUE_APP_URL}/api/aruna/do/unapprove`, {
                'deliv_id': this.po_item.deliv_id,
                'approve': 1
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading5 = false
                this.dialogDisagree = false
                this.getPullData()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully UnApprove 1",
                    visible: true
                };
            }).catch(err => {
                this.loading5 = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        async submitUnApprove2(){
            this.loading5 = true

            await axios.post(`${process.env.VUE_APP_URL}/api/aruna/do/unapprove`, {
                'deliv_id': this.po_item.deliv_id,
                'approve': 2
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading5 = false
                this.dialogDisagree2 = false
                this.getPullData()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully UnApprove 2",
                    visible: true
                };
            }).catch(err => {
                this.loading5 = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        
    },
    watch: {
    }
}
</script>